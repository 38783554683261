
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

$gap-h: 20px;
$gap-v: 32px;
$right-column-width: 335px;
$line-view-pad-r: 26px;

.ticket-item {
  $base-class: &;

  position: relative;
  margin-top: $gap-v;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 290px;
    margin-left: $gap-h;
  }

  &__cnt {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: $color-white;
    z-index: 2;
  }
  &__hat {
    position: absolute;
    top: -8px;
    left: 0;
    width: 96px;
    height: 24px;
    border-radius: 8px;
    z-index: 1;
  }

  &__body {
    flex-grow: 1;
    border-radius: 16px;
    background-color: $color-white;
    padding: 20px;
    box-shadow: 0 0 1px rgba(28, 41, 61, 0.1), 0 36px 36px rgba(28, 41, 61, 0.04);
  }
  &__head-block {
    margin-bottom: 12px;
  }
  &__head {
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__priority {
    display: inline-block;
    margin-right: 4px;
    .esmp-svg-icon {
      width: 12px;
      height: 12px;
    }
  }
  &__title {
    display: inline;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
  &__status-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  &__responsible {
    font-size: 10px;
    line-height: 16px;
    color: #797E8B;
  }
  &__description-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__description {
    margin-bottom: 16px;
    word-break: break-word;
  }
  &__description-title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  &__description-text {
    font-size: 12px;
    line-height: 16px;
    color: rgba(16, 24, 40, 0.7);
  }
  &__massive-fail {
    display: flex;
    color: $color-error-night;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  &__info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    &__name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #797E8B;
      margin-right: $base-gutter;
      .esmp-svg-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }
    &__value {
      .esmp-svg-icon {
        width: 16px;
        height: 16px;
      }
      .esmp-rate-star:last-child {
        margin-right: 0;
      }
    }

    &__show-customer-info-button {
      text-align: end;
      cursor: pointer;

      & > span {
        width: calc(100% - 16px);
        text-overflow: ellipsis;
        word-break: break-word;
        overflow: hidden;
      }
    }
  }
  &__info-item + &__info-item {
    margin-top: 8px;
  }

  &__select {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid $color-client-portal-logo;
    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      background-color: $color-client-portal-logo;
      border-radius: 50%;
      opacity: 0;
      transition: opacity $base-animation-time;
    }
    &--selected::before {
      opacity: 1;
    }
    &:hover {
      cursor:  pointer;
      &::before {
        opacity: 0.45;
      }
    }
  }

  &__actions {
    flex-grow: 0;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
    color: $color-black;
    transition: color $base-animation-time;
    width: 50%;
    position: relative;
    &--gray {
      color: rgba(16, 24, 40, 0.5);
    }
    &-icon {
      color: #FFBE08;
      display: inline-block;
      margin-right: 4px;
      margin-top: -6px;
      &.esmp-svg-icon {
        width: 15px;
        height: 15px;
      }
    }

    &:first-child:last-child {
      width: 100%;
    }
    &:nth-child(2) {
      border-left: 1px solid rgba(16, 24, 40, 0.1);
    }
    &:hover {
      color: $color-client-portal-logo;
      cursor: pointer;
    }
  }

  &__info-item-avatar {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-bottom: -4px;
    display: inline-block;
  }

  &--approval {
  }
  /** view card **/
  &--card {
    min-height: 370px;

    #{$base-class}__body {
      display: flex;
      flex-direction: column;
    }
    #{$base-class}__info-block {
      margin-top: auto;
      min-height: 64px;
    }
    &#{$base-class}--approval {
      #{$base-class}__head {
        padding-right: 30px;
      }
      #{$base-class}__head-block {
        position: relative;
      }
    }
  }
  /** view line **/
  &--line {
    width: 100%;
    margin-top: 8px;

    #{$base-class}__cnt {
      flex-direction: row;
    }
    #{$base-class}__hat {
      top: 0;
      left: -8px;
      width: 24px;
      height: 76px;
    }
    #{$base-class}__body {
      width: 82%;
      flex-grow: 1;
      display: flex;
    }
    #{$base-class}__select {
      top: calc(50% - 11px);
      left: 10px;
    }

    #{$base-class}__head-block {
      width: 32%;
      order: 0;
      padding-right: $line-view-pad-r;
      margin-bottom: 0;
      padding-left: 20px;
    }
    #{$base-class}__priority {
      margin-left: -20px;
    }
    #{$base-class}__title {
      font-size: 14px;
      line-height: 20px;
    }
    #{$base-class}__info-item {
      margin-top: 6px;
    }

    #{$base-class}__description-block {
      width: 24%;
      order: 1;
      padding-right: $line-view-pad-r;
      margin-bottom: 0;
    }
    #{$base-class}__description-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-clamp: 3;
      box-orient: vertical;
    }
    #{$base-class}__responsible-block {
      width: 12%;
      order: 2;
      padding-right: 20px;
      align-self: center;
    }

    #{$base-class}__info-block {
      width: 14%;
      order: 3;
      padding-right: 16px;
      align-self: center;
      #{$base-class}__info-item {
        display: block;
        text-align: right;
        &__name {
          justify-content: flex-end;
          color: $color-black;
          .esmp-svg-icon {
            display: none;
          }
        }
        &__value {
          .esmp-svg-icon {
            width: 16px;
            height: 16px;
          }
          .esmp-rate {
            display: flex;
            justify-content: right;
          }
          .esmp-rate-star {
            margin-right: math.div($base-gutter, 2);
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    #{$base-class}__status-block {
      width: 18%;
      order: 4;
      margin-bottom: 0;
      justify-content: flex-end;
    }

    #{$base-class}__actions {
      flex-grow: 1;
      width: 18%;
    }
  }
}
